import $ from 'jquery';
//
// import "scrollmagic";
//
window.ScrollMagic = require("scrollmagic");

var controller = new ScrollMagic.Controller();


$(function () { // wait for document ready
	// init
	var controller = new ScrollMagic.Controller({
		globalSceneOptions: {
			triggerHook: 'onEnter'
		}
	});

	// if (window.matchMedia("(min-width: 1025px)").matches) {
	// 	var container_scene = new ScrollMagic.Scene({triggerElement: "#nav-two", duration: "400%"})
	// 		.setPin("#dots-two")
	// 		// .addIndicators({name: "pin container"}) // add indicators (requires plugin)
	// 		.addTo(controller);
	// } else {
	// 	var container_scene = new ScrollMagic.Scene({triggerElement: "#nav-two", duration: "200%"})
	// 		.setPin("#dots-two")
	// 		// .addIndicators({name: "pin container"}) // add indicators (requires plugin)
	// 		.addTo(controller);
	// }

	var items_tween = new TimelineMax ()
		.add([
			TweenMax.fromTo("#magic-scroll-1", 1, {top: "30%"}, {top: "0%", ease: Power3.easeOut})
		]);

		if (window.matchMedia("(min-width: 1025px)").matches) {
			var items_scene = new ScrollMagic.Scene({triggerElement: "#dots-two", duration: "200%"})
				.setTween(items_tween)
				// .addIndicators({name: "items"}) // add indicators (requires plugin)
				.addTo(controller);
		} else {
			var items_scene = new ScrollMagic.Scene({triggerElement: "#dots-two", duration: "200%"})
				.setTween(items_tween)
				// .addIndicators({name: "items"}) // add indicators (requires plugin)
				.addTo(controller);
		}

		var items_tween_2 = new TimelineMax ()
			.add([
				TweenMax.fromTo("#magic-scroll-2", 1, {paddingTop: "40%"}, {paddingTop: "0%", ease: Power3.easeOut})
			]);

			if (window.matchMedia("(min-width: 1025px)").matches) {
				var items_scene = new ScrollMagic.Scene({triggerElement: "#storytelling-bg", duration: "250%"})
					.setTween(items_tween_2)
					// .addIndicators({name: "items 2"}) // add indicators (requires plugin)
					.addTo(controller);
			} else {
				var items_scene = new ScrollMagic.Scene({triggerElement: "#storytelling-bg", duration: "250%"})
					.setTween(items_tween_2)
					// .addIndicators({name: "items 2"}) // add indicators (requires plugin)
					.addTo(controller);
			}

			var items_tween_3 = new TimelineMax ()
				.add([
					TweenMax.fromTo("#magic-scroll-3", 1, {paddingTop: "10%", marginBottom: "-10%"}, {paddingTop: "0%", marginBottom: "-0%", ease: Power3.easeOut})
				]);

				if (window.matchMedia("(min-width: 1025px)").matches) {
					var items_scene = new ScrollMagic.Scene({triggerElement: ".link-to-pdf-section", duration: "100%"})
						.setTween(items_tween_3)
						// .addIndicators({name: "items 3"}) // add indicators (requires plugin)
						.addTo(controller);
				} else {
					var items_scene = new ScrollMagic.Scene({triggerElement: ".link-to-pdf-section", duration: "100%"})
						.setTween(items_tween_3)
						// .addIndicators({name: "items 3"}) // add indicators (requires plugin)
						.addTo(controller);
				}

				var items_tween_4 = new TimelineMax ()
					.add([
						TweenMax.fromTo("#magic-scroll-4", 1, {transform: "translateY(1000px)"}, {transform: "translateY(0)", ease: Power3.easeOut})
					]);

					if (window.matchMedia("(min-width: 1025px)").matches) {
						var items_scene = new ScrollMagic.Scene({triggerElement: "#pc-site-img", duration: "200%"})
							.setTween(items_tween_4)
							// .addIndicators({name: "items 4"}) // add indicators (requires plugin)
							.addTo(controller);
					} else {
						var items_scene = new ScrollMagic.Scene({triggerElement: "#pc-site-img", duration: "100%"})
							.setTween(items_tween_4)
							// .addIndicators({name: "items 4"}) // add indicators (requires plugin)
							.addTo(controller);
					}

});

// fallback: show controls if autoplay fails
// (needed for Samsung Internet for Android, as of v6.4)
// window.addEventListener('load', async () => {
//   let video = document.querySelector('video[muted][autoplay]');
//   try {
//     await video.play();
//   } catch (err) {
//     video.controls = true;
//   }
// });
